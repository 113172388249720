@use '../../../styles/mixins.scss' as ui;

.cover-flag {
  position: relative;
  width: ui.scale(30);
  height: ui.scale(144);
  z-index: 2;
  opacity: 0;
  will-change: transform;

  // .flag:last-child {
  //   margin-left: ui.scale(160);
  // }

  .flag {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;

    span {
      transition: none;
      transition-delay: 0s !important;
      transition-duration: 0s;
    }
    // span:nth-child(3) {
    //   z-index: 1;
    // }

    // &.two {
    //   span:nth-child(2) {
    //     z-index: 1;
    //   }
    // }
  }
}
