@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

@font-face {
   font-family: 'HelveticaNeueBold';
   src: url('~/src/assets/fonts/HelveticaNeue-Bold.woff') format('woff');
   font-weight: 700;
   font-style: normal;
 }

 @font-face {
  font-family: 'HelveticaNeue';
  src: url('~/src/assets/fonts/HelveticaNeue.woff2') format('woff');
  font-style: normal;
}

