@use '../../styles/mixins.scss' as ui;

.match-section {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  pointer-events: none;

  .scroll {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 10;
    pointer-events: none;
    .arrow-button {
      bottom: ui.scale(120);
      @include ui.until(desktop) {
        bottom: ui.scale(60);
      }
      left: 50%;
    }
  }

  @include ui.until(desktop) {
    width: calc(100vw - 50px);
    left: 50%;
    transform: translateX(-50%);
  }

  .home {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 60px;
    pointer-events: all;
    @include ui.until(desktop) {
      width: 100%;
      left: 0;
      top: 5vh;
      transform: translateY(0);
    }
  }

  .visitor {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 60px;
    pointer-events: all;
    @include ui.until(desktop) {
      transform: translateY(0);
      width: 100%;
      left: 0;
      top: initial;
      right: initial;
      bottom: 0vh;
    }
  }
}

.menu-label {
  position: absolute;
  overflow: hidden;

  .player,
  .minute {
    font-family: 'Roboto Mono', monospace;
    font-size: 14px;
    color: white;
    text-transform: uppercase;
    display: inline-block;
    transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1);

    &.show {
      transform: translate(0, 0);
    }
  }

  .player {
    text-align: right;
    transform: translateX(-100%);
  }

  .minute {
    margin-left: 6px;
    transform: translateX(100%);
  }
}
