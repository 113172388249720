@use '../../../styles/mixins.scss' as ui;

.groups {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  @include ui.until(desktop) {
    width: calc(100% - 50px);
  }

  .cover-flag {
    height: ui.scale(60);
    @include ui.until(desktop) {
      width: 100%;
      height: 50%;
    }
    .flag {
      @include ui.until(desktop) {
        width: 100%;
        height: 84%;
      }
    }
  }

  @include ui.until(desktop) {
    transform: scale(0.8, 1.2);
  }
  .group {
    width: ui.scale(86);
    margin-right: ui.scale(80);

    @include ui.until(desktop) {
      width: 9%;
      margin-right: 4%;
      // flex-shrink: 0;
    }

    &:last-child {
      margin-right: 0;
    }
    .group-match {
      width: 100%;
      height: ui.scale(60);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: ui.scale(60);
      @include ui.until(desktop) {
        height: ui.scale(53);
        margin-bottom: 1vh;
        flex-direction: column;
        width: 100%;
      }
    }

    .group-match.over {
      .flag::after {
        opacity: 1;
      }
    }
  }
}
