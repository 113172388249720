@use '../../styles/mixins.scss' as ui;

.match-score {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  pointer-events: all;
  @include ui.until(desktop) {
    width: 100%;
    justify-content: space-between;
  }

  .match-flag {
    margin-right: ui.scale(88);
  }

  .score {
    opacity: 0;
    @include ui.from(desktop) {
      margin-right: ui.scale(55);
    }
    p {
      font-family: 'HelveticaNeue';
      font-size: ui.scale(300);
      color: white;
    }
  }

  .team {
    opacity: 0;
    p {
      font-family: 'HelveticaNeue';
      font-size: ui.scale(60);
      color: white;
    }
  }

  &.match-score-visitor {
    flex-direction: row-reverse;

    .score {
      @include ui.from(desktop) {
        margin-left: ui.scale(55);
        margin-right: ui.scale(0);
      }
    }
    .match-flag {
      margin-right: ui.scale(0);
      margin-left: ui.scale(88);
    }
  }
}
