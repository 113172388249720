@use '../../styles/mixins.scss' as ui;

.bt-toggle-view {
  background: transparent;
  border: 0;
  padding: 0;
  padding: 0;
  margin: 0;
  width: ui.scale(30);
  height: ui.scale(30);
  cursor: pointer;
  right: 60px;
  top: ui.scale(60);
  position: absolute;
  z-index: 11;
  box-sizing: border-box;

  &::before {
    content: ' ';
    width: 180%;
    height: 180%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .square {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 2;
  }

  .square-animation {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
  }

  svg {
    height: 100%;
    position: absolute;
    opacity: 0;
    transform: translateX(ui.scale(-120));
  }

  @include ui.until(desktop) {
    right: 25px;
  }

  .left,
  .right {
    display: block;
    position: absolute;
    width: 2px;
    height: 13px;
    left: 50%;
    top: 50%;
    background-color: black;
    transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1, 0);
    transition: transform 0.5s ui.$easeOutExpo 0.1s, opacity 0.1s ui.$easeOutExpo;
    opacity: 0;
  }

  .right {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(1, 0);
  }

  &.close {
    .left {
      transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1, 1);
      opacity: 1;
    }
    .right {
      opacity: 1;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(1, 1);
    }
  }
}
