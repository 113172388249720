@use '../../styles/mixins.scss' as ui;

.layout {
  position: relative;
  background: black;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  .bt-toggle-view {
    transform: translateY(-10vh);
    transition: transform 1s ui.$easeInOutExpo;
  }

  &.show {
    .layout-logo {
      transform: translateY(0);
    }
    .bt-toggle-view {
      transform: translateY(0);
    }
  }

  .preload {
    pointer-events: none;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 50%;
    left: 50%;
    width: ui.scale(100);
    height: ui.scale(100);
    transform: translateX(-50%) translateY(-50%);

    &-logo {
      position: absolute;
      height: ui.scale(104);
      opacity: 0;
      svg {
        height: 100%;
      }
    }
    &-square {
      opacity: 0;
      position: absolute;
      width: ui.scale(100);
      height: ui.scale(100);
      background-color: white;
      overflow: hidden;
    }
  }

  .gl-app {
    @include ui.until(desktop) {
      margin-top: 3vh;
    }
  }
}

html {
  @include ui.responsive-scale(1024, 2100, $aspect-ratio: 1.777, $limit-scale: false);
}
