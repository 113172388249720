@use '../../styles/mixins.scss' as ui;

.layout-logo {
  width: ui.scale(20);
  height: ui.scale(31);
  position: absolute;
  top: ui.scale(60);
  left: 60px;
  transform: translateY(-10vh);
  transition: transform 1s ui.$easeInOutExpo;
  cursor: pointer;
  z-index: 13;
  svg {
    width: 100%;
  }

  .square {
    width: ui.scale(30);
    height: ui.scale(30);
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
  }

  @include ui.until(desktop) {
    left: 25px;
  }
}
