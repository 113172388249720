@use '../../../styles/mixins.scss' as ui;

.flag {
  position: relative;
  width: ui.scale(30);
  height: ui.scale(60);
  z-index: 2;
  overflow: hidden;

  .img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    object-fit: cover;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
  }

  &.big {
    height: ui.scale(144);
  }

  &::after {
    content: ' ';
    width: 100%;
    height: 100%;
    border: 1px solid white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    opacity: 0;
    box-sizing: border-box;
  }

  &.show {
    span {
      transform: scale(1, 1);
      transition-delay: 0s;
    }

    span:nth-child(1) {
      transition-delay: 0.2s;
    }

    span:nth-child(2) {
      transition-delay: 0s;
    }

    span:nth-child(3) {
      transition-delay: 0.2s;
    }
  }

  span {
    width: 100%;
    height: 34%;
    will-change: transform;
    transform: scale(1, 0);
    position: absolute;
    display: block;
    // transition: transform 1s ui.$easeOutExpo 0.5s;
  }

  span:nth-child(2) {
    top: 0;
    height: 100%;
    background-color: #1b1b1b;
    transform-origin: center left;
    transition-duration: 0.75s;
    transition-delay: 0.5s;
  }

  span:nth-child(1) {
    z-index: 1;
    top: -1px;
    background-color: #272727;
    transform-origin: bottom left;
    transition-duration: 0.5s;
    transition-delay: 0.7s;
  }

  span:nth-child(3) {
    transition-delay: 0.7s;
    z-index: 1;
    top: initial;
    bottom: -1px;
    background-color: #272727;
    transform-origin: top left;
    transition-duration: 0.5;
  }

  &.two {
    span:nth-child(1),
    span:nth-child(2) {
      width: 100%;
      height: 50%;
      top: 0;
      transform: scale(1, 0);
      transform-origin: bottom left;
    }
    span:nth-child(2) {
      transform: scale(1, 0);
      height: 50%;
      top: initial;
      bottom: 0;
      transform-origin: top left;
    }
  }

  &.show.two {
    span {
      transform: scale(1, 1);
    }
  }
}
