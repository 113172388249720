@use '../../../styles/mixins.scss' as ui;

.arrow-button {
  background: transparent;
  border: 0;
  padding: 0;
  padding: 0;
  margin: 0;
  width: ui.scale(30);
  height: ui.scale(30);
  cursor: pointer;
  left: 50%;
  position: absolute;
  pointer-events: all;
  &:before {
    content: '';
    width: 200%;
    height: 150%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-10%);
  }

  .icon {
    width: 8px;
    height: 4px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-60px);
    bottom: 0;
    opacity: 0;
    svg {
      width: 100%;
      display: block;
    }
  }

  .line {
    position: absolute;
    height: 60px;
    width: 1px;
    left: 50%;
    bottom: 0;
    overflow: hidden;
    span {
      position: absolute;
      background-color: white;
      transform: translateY(-100%);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
    }
  }
}
