@use '../../../styles/mixins.scss' as ui;

.knockout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // width: 100%;
  // height: 100%;

  @include ui.until(desktop) {
    transform: rotate(90deg);
  }

  .team {
    width: ui.scale(30);
    height: ui.scale(60);
    z-index: 2;
    @include ui.until(desktop) {
      transform: scale(1.8, 1.5);
    }

    position: relative;
    cursor: pointer;
  }

  .match {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }
    opacity: 0;
    &.show {
      opacity: 1;
    }

    &.disabled {
      pointer-events: none;
    }

    &.over {
      .flag::after {
        opacity: 1;
      }

      .lines {
        div span {
          opacity: 1;
        }
      }
    }
  }

  .lines {
    position: absolute;
    top: 50%;
    left: ui.scale(15);
    transform: translateX(-0%) translateY(-50%);

    div {
      will-change: transform;
    }

    div span {
      width: 100%;
      height: 100%;
      background-color: white;
      display: block;
      position: absolute;
      opacity: 0;
    }

    .top {
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #2d2d2d;
      top: 0;
      transform: scaleX(0);
    }
    .middle {
      transform: scaleY(0);
      position: absolute;
      right: 0;
      height: 100%;
      width: 1px;
      background-color: #2d2d2d;
    }
    .bottom {
      transform: scaleX(0);
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #2d2d2d;
      bottom: 0;
    }
  }

  .side-a,
  .side-b {
    position: relative;
    display: flex;
    flex-direction: row;

    .round-16 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: ui.scale(65);

      @include ui.until(desktop) {
        margin-right: ui.scale(108);
      }

      .lines {
        width: ui.scale(95);
        height: ui.scale(115);
        @include ui.until(desktop) {
          width: ui.scale(140);
        }
      }

      .team {
        width: ui.scale(30);
        display: flex;
        flex-direction: column;
      }
      .match {
        height: ui.scale(175);
        margin-bottom: ui.scale(66);
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .quarters {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: ui.scale(220);
      @include ui.until(desktop) {
        margin-right: ui.scale(120);
      }

      .lines {
        width: ui.scale(250);
        height: ui.scale(242);
        @include ui.until(desktop) {
          width: ui.scale(151);
        }
      }

      .match {
        height: ui.scale(300);
        margin-bottom: ui.scale(180);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .semis {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: ui.scale(200);
      @include ui.until(desktop) {
        margin-right: ui.scale(130);
      }
      .lines {
        width: ui.scale(232);
        height: ui.scale(464);
        @include ui.until(desktop) {
          width: ui.scale(162);
        }
      }
      .match {
        height: ui.scale(524);
        margin-bottom: ui.scale(405);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .team:last-child {
      margin-bottom: 0;
    }
  }

  .final {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ui.scale(360);

    .match {
      width: ui.scale(360);
      flex-direction: row;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .lines {
      display: none;
    }
  }

  .side-b {
    .lines {
      position: absolute;
      top: 50%;
      right: ui.scale(15);
      transform: translateX(-100%) translateY(-50%);
      .middle {
        position: absolute;
        right: initial;
        left: 0;
        height: 100%;
        width: 1px;
      }
    }

    .round-16 {
      margin-left: ui.scale(65);
      margin-right: 0;
      @include ui.until(desktop) {
        margin-left: ui.scale(108);
      }
      .lines {
        @include ui.until(desktop) {
          width: ui.scale(140);
        }
      }
    }
    .quarters {
      margin-left: ui.scale(220);
      margin-right: 0;
      @include ui.until(desktop) {
        margin-left: ui.scale(120);
      }
      .lines {
        @include ui.until(desktop) {
          width: ui.scale(151);
        }
      }
    }

    .semis {
      margin-right: 0;
      margin-left: ui.scale(200);
      @include ui.until(desktop) {
        margin-left: ui.scale(130);
      }
      .lines {
        @include ui.until(desktop) {
          width: ui.scale(162);
        }
      }
    }
  }

  .winner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) scale(1.8, 1.5);
    display: block;
  }
}
