@import './styles/fonts.css';
@import './styles/reset.scss';

/* disable elastic scrolling */
html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  overflow: hidden;
}
