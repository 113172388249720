@use '../../styles/mixins.scss' as ui;

.matches {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  background-color: black;
  z-index: 10;

  .container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  .knockout-table,
  .group-table {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .knockout-table {
    top: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top-nav,
  .bottom-nav {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    pointer-events: none;
  }

  .top-nav {
    top: ui.scale(120);
    @include ui.until(desktop) {
      top: 10vh;
    }
  }

  .top-nav .arrow-button {
    transform: rotate(180deg);
  }

  .bottom-nav .arrow-button {
    bottom: ui.scale(120);
    @include ui.until(desktop) {
      bottom: 15vh;
    }
  }
}
