@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap";
@font-face {
  font-family: HelveticaNeueBold;
  src: url("HelveticaNeue-Bold.aea37ace.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: HelveticaNeue;
  src: url("HelveticaNeue.33c5b4ec.woff2") format("woff");
  font-style: normal;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, body {
  height: 100%;
  overflow: hidden;
}



.matches {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.matches .container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.matches .knockout-table, .matches .group-table {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.matches .knockout-table {
  justify-content: center;
  align-items: center;
  display: flex;
  top: 100vh;
}

.matches .top-nav, .matches .bottom-nav {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  pointer-events: none;
  position: absolute;
}

.matches .top-nav {
  top: calc(120 * var(--scale));
}

@media screen and (max-width: 1023px) {
  .matches .top-nav {
    top: 10vh;
  }
}

.matches .top-nav .arrow-button {
  transform: rotate(180deg);
}

.matches .bottom-nav .arrow-button {
  bottom: calc(120 * var(--scale));
}

@media screen and (max-width: 1023px) {
  .matches .bottom-nav .arrow-button {
    bottom: 15vh;
  }
}

.groups {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1023px) {
  .groups {
    width: calc(100% - 50px);
  }
}

.groups .cover-flag {
  height: calc(60 * var(--scale));
}

@media screen and (max-width: 1023px) {
  .groups .cover-flag {
    width: 100%;
    height: 50%;
  }

  .groups .cover-flag .flag {
    width: 100%;
    height: 84%;
  }

  .groups {
    transform: scale(.8, 1.2);
  }
}

.groups .group {
  width: calc(86 * var(--scale));
  margin-right: calc(80 * var(--scale));
}

@media screen and (max-width: 1023px) {
  .groups .group {
    width: 9%;
    margin-right: 4%;
  }
}

.groups .group:last-child {
  margin-right: 0;
}

.groups .group .group-match {
  width: 100%;
  height: calc(60 * var(--scale));
  cursor: pointer;
  margin-bottom: calc(60 * var(--scale));
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 1023px) {
  .groups .group .group-match {
    height: calc(53 * var(--scale));
    width: 100%;
    flex-direction: column;
    margin-bottom: 1vh;
  }
}

.groups .group .group-match.over .flag:after {
  opacity: 1;
}

.knockout {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

@media screen and (max-width: 1023px) {
  .knockout {
    transform: rotate(90deg);
  }
}

.knockout .team {
  width: calc(30 * var(--scale));
  height: calc(60 * var(--scale));
  z-index: 2;
  cursor: pointer;
  position: relative;
}

@media screen and (max-width: 1023px) {
  .knockout .team {
    transform: scale(1.8, 1.5);
  }
}

.knockout .match {
  opacity: 0;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.knockout .match:last-child {
  margin-bottom: 0;
}

.knockout .match.show {
  opacity: 1;
}

.knockout .match.disabled {
  pointer-events: none;
}

.knockout .match.over .flag:after, .knockout .match.over .lines div span {
  opacity: 1;
}

.knockout .lines {
  top: 50%;
  left: calc(15 * var(--scale));
  position: absolute;
  transform: translateX(0%)translateY(-50%);
}

.knockout .lines div {
  will-change: transform;
}

.knockout .lines div span {
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #fff;
  display: block;
  position: absolute;
}

.knockout .lines .top {
  width: 100%;
  height: 1px;
  background-color: #2d2d2d;
  position: absolute;
  top: 0;
  transform: scaleX(0);
}

.knockout .lines .middle {
  height: 100%;
  width: 1px;
  background-color: #2d2d2d;
  position: absolute;
  right: 0;
  transform: scaleY(0);
}

.knockout .lines .bottom {
  width: 100%;
  height: 1px;
  background-color: #2d2d2d;
  position: absolute;
  bottom: 0;
  transform: scaleX(0);
}

.knockout .side-a, .knockout .side-b {
  flex-direction: row;
  display: flex;
  position: relative;
}

.knockout .side-a .round-16, .knockout .side-b .round-16 {
  margin-right: calc(65 * var(--scale));
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media screen and (max-width: 1023px) {
  .knockout .side-a .round-16, .knockout .side-b .round-16 {
    margin-right: calc(108 * var(--scale));
  }
}

.knockout .side-a .round-16 .lines, .knockout .side-b .round-16 .lines {
  width: calc(95 * var(--scale));
  height: calc(115 * var(--scale));
}

@media screen and (max-width: 1023px) {
  .knockout .side-a .round-16 .lines, .knockout .side-b .round-16 .lines {
    width: calc(140 * var(--scale));
  }
}

.knockout .side-a .round-16 .team, .knockout .side-b .round-16 .team {
  width: calc(30 * var(--scale));
  flex-direction: column;
  display: flex;
}

.knockout .side-a .round-16 .match, .knockout .side-b .round-16 .match {
  height: calc(175 * var(--scale));
  margin-bottom: calc(66 * var(--scale));
  position: relative;
}

.knockout .side-a .round-16 .match:last-child, .knockout .side-b .round-16 .match:last-child {
  margin-bottom: 0;
}

.knockout .side-a .quarters, .knockout .side-b .quarters {
  margin-right: calc(220 * var(--scale));
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media screen and (max-width: 1023px) {
  .knockout .side-a .quarters, .knockout .side-b .quarters {
    margin-right: calc(120 * var(--scale));
  }
}

.knockout .side-a .quarters .lines, .knockout .side-b .quarters .lines {
  width: calc(250 * var(--scale));
  height: calc(242 * var(--scale));
}

@media screen and (max-width: 1023px) {
  .knockout .side-a .quarters .lines, .knockout .side-b .quarters .lines {
    width: calc(151 * var(--scale));
  }
}

.knockout .side-a .quarters .match, .knockout .side-b .quarters .match {
  height: calc(300 * var(--scale));
  margin-bottom: calc(180 * var(--scale));
}

.knockout .side-a .quarters .match:last-child, .knockout .side-b .quarters .match:last-child {
  margin-bottom: 0;
}

.knockout .side-a .semis, .knockout .side-b .semis {
  margin-right: calc(200 * var(--scale));
  flex-direction: column;
  justify-content: center;
  display: flex;
}

@media screen and (max-width: 1023px) {
  .knockout .side-a .semis, .knockout .side-b .semis {
    margin-right: calc(130 * var(--scale));
  }
}

.knockout .side-a .semis .lines, .knockout .side-b .semis .lines {
  width: calc(232 * var(--scale));
  height: calc(464 * var(--scale));
}

@media screen and (max-width: 1023px) {
  .knockout .side-a .semis .lines, .knockout .side-b .semis .lines {
    width: calc(162 * var(--scale));
  }
}

.knockout .side-a .semis .match, .knockout .side-b .semis .match {
  height: calc(524 * var(--scale));
  margin-bottom: calc(405 * var(--scale));
}

.knockout .side-a .semis .match:last-child, .knockout .side-b .semis .match:last-child, .knockout .side-a .team:last-child, .knockout .side-b .team:last-child {
  margin-bottom: 0;
}

.knockout .final {
  width: calc(360 * var(--scale));
  justify-content: center;
  align-items: center;
  display: flex;
}

.knockout .final .match {
  width: calc(360 * var(--scale));
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.knockout .final .lines {
  display: none;
}

.knockout .side-b .lines {
  top: 50%;
  right: calc(15 * var(--scale));
  position: absolute;
  transform: translateX(-100%)translateY(-50%);
}

.knockout .side-b .lines .middle {
  right: initial;
  height: 100%;
  width: 1px;
  position: absolute;
  left: 0;
}

.knockout .side-b .round-16 {
  margin-left: calc(65 * var(--scale));
  margin-right: 0;
}

@media screen and (max-width: 1023px) {
  .knockout .side-b .round-16 {
    margin-left: calc(108 * var(--scale));
  }

  .knockout .side-b .round-16 .lines {
    width: calc(140 * var(--scale));
  }
}

.knockout .side-b .quarters {
  margin-left: calc(220 * var(--scale));
  margin-right: 0;
}

@media screen and (max-width: 1023px) {
  .knockout .side-b .quarters {
    margin-left: calc(120 * var(--scale));
  }

  .knockout .side-b .quarters .lines {
    width: calc(151 * var(--scale));
  }
}

.knockout .side-b .semis {
  margin-right: 0;
  margin-left: calc(200 * var(--scale));
}

@media screen and (max-width: 1023px) {
  .knockout .side-b .semis {
    margin-left: calc(130 * var(--scale));
  }

  .knockout .side-b .semis .lines {
    width: calc(162 * var(--scale));
  }
}

.knockout .winner {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)scale(1.8, 1.5);
}

.match-section {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.match-section .scroll {
  width: 100%;
  height: 100vh;
  z-index: 10;
  pointer-events: none;
  position: absolute;
}

.match-section .scroll .arrow-button {
  bottom: calc(120 * var(--scale));
  left: 50%;
}

@media screen and (max-width: 1023px) {
  .match-section .scroll .arrow-button {
    bottom: calc(60 * var(--scale));
  }

  .match-section {
    width: calc(100vw - 50px);
    left: 50%;
    transform: translateX(-50%);
  }
}

.match-section .home {
  pointer-events: all;
  position: absolute;
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
}

@media screen and (max-width: 1023px) {
  .match-section .home {
    width: 100%;
    top: 5vh;
    left: 0;
    transform: translateY(0);
  }
}

.match-section .visitor {
  pointer-events: all;
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
}

@media screen and (max-width: 1023px) {
  .match-section .visitor {
    width: 100%;
    left: 0;
    top: initial;
    right: initial;
    bottom: 0;
    transform: translateY(0);
  }
}

.menu-label {
  position: absolute;
  overflow: hidden;
}

.menu-label .player, .menu-label .minute {
  color: #fff;
  text-transform: uppercase;
  font-family: Roboto Mono, monospace;
  font-size: 14px;
  transition: transform .4s cubic-bezier(.16, 1, .3, 1);
  display: inline-block;
}

.menu-label .player.show, .menu-label .minute.show {
  transform: translate(0);
}

.menu-label .player {
  text-align: right;
  transform: translateX(-100%);
}

.menu-label .minute {
  margin-left: 6px;
  transform: translateX(100%);
}

.arrow-button {
  width: calc(30 * var(--scale));
  height: calc(30 * var(--scale));
  cursor: pointer;
  pointer-events: all;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
}

.arrow-button:before {
  content: "";
  width: 200%;
  height: 150%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-10%);
}

.arrow-button .icon {
  width: 8px;
  height: 4px;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%)translateY(-60px);
}

.arrow-button .icon svg {
  width: 100%;
  display: block;
}

.arrow-button .line {
  height: 60px;
  width: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow: hidden;
}

.arrow-button .line span {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.flag {
  width: calc(30 * var(--scale));
  height: calc(60 * var(--scale));
  z-index: 2;
  position: relative;
  overflow: hidden;
}

.flag .img {
  width: 100%;
  height: 100%;
  z-index: 10;
  object-fit: cover;
  position: absolute;
}

.flag img {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
}

.flag.big {
  height: calc(144 * var(--scale));
}

.flag:after {
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 20;
  opacity: 0;
  box-sizing: border-box;
  border: 1px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.flag.show span {
  transition-delay: 0s;
  transform: scale(1);
}

.flag.show span:nth-child(1) {
  transition-delay: .2s;
}

.flag.show span:nth-child(2) {
  transition-delay: 0s;
}

.flag.show span:nth-child(3) {
  transition-delay: .2s;
}

.flag span {
  width: 100%;
  height: 34%;
  will-change: transform;
  display: block;
  position: absolute;
  transform: scale(1, 0);
}

.flag span:nth-child(2) {
  height: 100%;
  transform-origin: 0;
  background-color: #1b1b1b;
  transition-duration: .75s;
  transition-delay: .5s;
  top: 0;
}

.flag span:nth-child(1) {
  z-index: 1;
  transform-origin: 0 100%;
  background-color: #272727;
  transition-duration: .5s;
  transition-delay: .7s;
  top: -1px;
}

.flag span:nth-child(3) {
  z-index: 1;
  top: initial;
  transform-origin: 0 0;
  transition-delay: .7s;
  transition-duration: .5;
  background-color: #272727;
  bottom: -1px;
}

.flag.two span:nth-child(1), .flag.two span:nth-child(2) {
  width: 100%;
  height: 50%;
  transform-origin: 0 100%;
  top: 0;
  transform: scale(1, 0);
}

.flag.two span:nth-child(2) {
  height: 50%;
  top: initial;
  transform-origin: 0 0;
  bottom: 0;
  transform: scale(1, 0);
}

.flag.show.two span {
  transform: scale(1);
}

.cover-flag {
  width: calc(30 * var(--scale));
  height: calc(144 * var(--scale));
  z-index: 2;
  opacity: 0;
  will-change: transform;
  position: relative;
}

.cover-flag .flag {
  will-change: transform;
  position: absolute;
  top: 0;
  left: 0;
}

.cover-flag .flag span {
  transition: none;
  transition-delay: 0s !important;
}

.match-score {
  cursor: pointer;
  pointer-events: all;
  flex-direction: row;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 1023px) {
  .match-score {
    width: 100%;
    justify-content: space-between;
  }
}

.match-score .match-flag {
  margin-right: calc(88 * var(--scale));
}

.match-score .score {
  opacity: 0;
}

@media screen and (min-width: 1024px) {
  .match-score .score {
    margin-right: calc(55 * var(--scale));
  }
}

.match-score .score p {
  font-family: HelveticaNeue;
  font-size: calc(300 * var(--scale));
  color: #fff;
}

.match-score .team {
  opacity: 0;
}

.match-score .team p {
  font-family: HelveticaNeue;
  font-size: calc(60 * var(--scale));
  color: #fff;
}

.match-score.match-score-visitor {
  flex-direction: row-reverse;
}

@media screen and (min-width: 1024px) {
  .match-score.match-score-visitor .score {
    margin-left: calc(55 * var(--scale));
    margin-right: calc(0 * var(--scale));
  }
}

.match-score.match-score-visitor .match-flag {
  margin-right: calc(0 * var(--scale));
  margin-left: calc(88 * var(--scale));
}

.layout {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: #000;
  position: relative;
  overflow: hidden;
}

.layout .bt-toggle-view {
  transition: transform 1s cubic-bezier(1, 0, 0, 1);
  transform: translateY(-10vh);
}

.layout.show .layout-logo, .layout.show .bt-toggle-view {
  transform: translateY(0);
}

.layout .preload {
  pointer-events: none;
  width: calc(100 * var(--scale));
  height: calc(100 * var(--scale));
  flex-direction: row;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.layout .preload-logo {
  height: calc(104 * var(--scale));
  opacity: 0;
  position: absolute;
}

.layout .preload-logo svg {
  height: 100%;
}

.layout .preload-square {
  opacity: 0;
  width: calc(100 * var(--scale));
  height: calc(100 * var(--scale));
  background-color: #fff;
  position: absolute;
  overflow: hidden;
}

@media screen and (max-width: 1023px) {
  .layout .gl-app {
    margin-top: 3vh;
  }
}

html {
  --scale: .487619px;
  min-height: 0;
}

@media only screen and (min-width: 1024px) {
  html {
    --scale: calc( .487619px + .512381 * ( 100vw - 1024px ) / 1076 );
  }
}

@media only screen and (min-aspect-ratio: 178 / 100) {
  html {
    --scale: .487619px;
    min-height: 0;
  }
}

@media only screen and (min-aspect-ratio: 178 / 100) and (min-height: 576.252px) {
  html {
    --scale: calc( .487619px + .512381 * ( 100vh - 576.252px ) / 605.515 );
  }
}

.layout-logo {
  width: calc(20 * var(--scale));
  height: calc(31 * var(--scale));
  top: calc(60 * var(--scale));
  cursor: pointer;
  z-index: 13;
  transition: transform 1s cubic-bezier(1, 0, 0, 1);
  position: absolute;
  left: 60px;
  transform: translateY(-10vh);
}

.layout-logo svg {
  width: 100%;
}

.layout-logo .square {
  width: calc(30 * var(--scale));
  height: calc(30 * var(--scale));
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
}

@media screen and (max-width: 1023px) {
  .layout-logo {
    left: 25px;
  }
}

.bt-toggle-view {
  width: calc(30 * var(--scale));
  height: calc(30 * var(--scale));
  cursor: pointer;
  right: 60px;
  top: calc(60 * var(--scale));
  z-index: 11;
  box-sizing: border-box;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
}

.bt-toggle-view:before {
  content: " ";
  width: 180%;
  height: 180%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.bt-toggle-view .square {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  position: absolute;
}

.bt-toggle-view .square-animation {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
}

.bt-toggle-view svg {
  height: 100%;
  opacity: 0;
  transform: translateX(calc(-120 * var(--scale)) );
  position: absolute;
}

@media screen and (max-width: 1023px) {
  .bt-toggle-view {
    right: 25px;
  }
}

.bt-toggle-view .left, .bt-toggle-view .right {
  width: 2px;
  height: 13px;
  opacity: 0;
  background-color: #000;
  transition: transform .5s cubic-bezier(.19, 1, .22, 1) .1s, opacity .1s cubic-bezier(.19, 1, .22, 1);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%)rotate(45deg)scale(1, 0);
}

.bt-toggle-view .right {
  transform: translateX(-50%)translateY(-50%)rotate(-45deg)scale(1, 0);
}

.bt-toggle-view.close .left {
  opacity: 1;
  transform: translateX(-50%)translateY(-50%)rotate(45deg)scale(1);
}

.bt-toggle-view.close .right {
  opacity: 1;
  transform: translateX(-50%)translateY(-50%)rotate(-45deg)scale(1);
}

/*# sourceMappingURL=index.1064e6f1.css.map */
